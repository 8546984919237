define("itarp-business-application/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('profile', {
      path: '/'
    }, function () {
      this.route('autofill');
      this.route('basic-info');
      this.route('expertise');
      this.route('work-arrangement');
    });
    this.route('my-favorites');
    this.route('my-favorite-talentclouds');
    this.mount('itarp-opportunity-ui', {
      path: 'opportunities'
    });
    this.mount('itarp-talentcloud-ui', {
      path: 'talentclouds'
    });
    this.route('profile-preview'); // this.route('dynamic-route', { path: '*path' });

    this.route('chat', {
      path: '/messages'
    }, function () {
      this.route('messages', {
        path: '/:id'
      });
    });
    this.route('account', function () {
      this.route('get-started');
      this.route('verify-email');
    });
  });

  _exports.default = _default;
});