define("itarp-business-application/routes/account/get-started", ["exports", "itarp-auth-addon/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AccountGetStartedRoute extends _application.default {
    buildRouteInfoMetadata() {
      return {
        title: 'Create an Expert Account',
        ui: {
          padding: {
            top: 0
          },
          sidebarHidden: true
        },
        accessControl: {
          accountTypes: ['tod_client']
        }
      };
    }

    model() {
      return this.modelFor('account');
    }

  }

  _exports.default = AccountGetStartedRoute;
});