define("itarp-expert-preview/helpers/index-of", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   index start with 1, not with 0
   */
  function indexOf([item, items]) {
    if (!(0, _array.isArray)(items)) return -1;
    const index = (0, _array.A)(items).indexOf(item) + 1;
    return index;
  }

  var _default = (0, _helper.helper)(indexOf);

  _exports.default = _default;
});