define("shared/serializers/user", ["exports", "@ember-data/serializer/rest", "shared/serializers/data"], function (_exports, _rest, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        profiles: {
          embedded: 'always'
        }
      });

      _defineProperty(this, "mode", {});
    }

    serialize(snapshot, options) {
      const page = snapshot.record.page;
      const json = super.serialize(...arguments);

      if (snapshot.adapterOptions && snapshot.adapterOptions.mode) {
        const mode = snapshot.adapterOptions.mode;
        this.mode[snapshot.record.id] = mode;

        if (mode === 'experiences') {
          return this.serializeExperiencesOnly(json);
        } else if (mode === 'educations') {
          return this.serializeEducationsOnly(json);
        } else if (mode === 'certifications') {
          return this.serializeCertificationsOnly(json);
        } else if (mode === 'profilePicture') {
          return this.serializeProfilePictureOnly(json);
        } else if (mode === 'firmInfo') {
          return this.serializeFirmInfoOnly(json);
        }
      }

      const profiles = json.users.profiles.profiles;
      json.users.profiles = profiles;
      delete json.users.page;
      delete json.users._id;
      json.page = page;
      return json;
    }

    serializeEducationsOnly(json) {
      return {
        users: {
          profiles: {
            educations: json.users.profiles.profiles.educations
          }
        }
      };
    }

    serializeExperiencesOnly(json) {
      return {
        users: {
          profiles: {
            experiences: json.users.profiles.profiles.experiences
          }
        }
      };
    }

    serializeCertificationsOnly(json) {
      return {
        users: {
          profiles: {
            certifications: json.users.profiles.profiles.certifications
          }
        }
      };
    }

    serializeProfilePictureOnly(json) {
      return {
        users: {
          profiles: {
            profile_picture: json.users.profiles.profiles.profile_picture
          }
        }
      };
    }

    serializeFirmInfoOnly(json) {
      return {
        users: {
          profiles: {
            firm_name: json.users.profiles.profiles.firm_name,
            firm_website: json.users.profiles.profiles.firm_website,
            firm_size: json.users.profiles.profiles.firm_size
          }
        }
      };
    }

    normalize(modelClass, resourceHash) {
      const mode = this.mode[resourceHash._id];
      if (!mode) return super.normalize(...arguments);
      delete this.mode[resourceHash._id];

      if (mode === 'experiences') {
        return this.normalizeExperiencesOnly(modelClass, resourceHash);
      } else if (mode === 'educations') {
        return this.normalizeEducationsOnly(modelClass, resourceHash);
      } else if (mode === 'certifications') {
        return this.normalizeCertificationsOnly(modelClass, resourceHash);
      } else if (mode === 'profilePicture') {
        return this.normalizeProfilePictureOnly(modelClass, resourceHash);
      } else if (mode === 'firmInfo') {
        return this.normalizeFirmInfoOnly(modelClass, resourceHash);
      }

      return super.normalize(...arguments);
    }

    normalizeExperiencesOnly(modelClass, resourceHash) {
      return super.normalize(modelClass, {
        _id: resourceHash._id,
        profiles: {
          _id: resourceHash.profiles._id,
          experiences: resourceHash.profiles.experiences
        }
      });
    }

    normalizeEducationsOnly(modelClass, resourceHash) {
      return super.normalize(modelClass, {
        _id: resourceHash._id,
        profiles: {
          _id: resourceHash.profiles._id,
          educations: resourceHash.profiles.educations
        }
      });
    }

    normalizeCertificationsOnly(modelClass, resourceHash) {
      return super.normalize(modelClass, {
        _id: resourceHash._id,
        profiles: {
          _id: resourceHash.profiles._id,
          certifications: resourceHash.profiles.certifications
        }
      });
    }

    normalizeProfilePictureOnly(modelClass, resourceHash) {
      return super.normalize(modelClass, {
        _id: resourceHash._id,
        profiles: {
          _id: resourceHash.profiles._id,
          profile_picture: resourceHash.profiles.profile_picture
        }
      });
    }

    normalizeFirmInfoOnly(modelClass, resourceHash) {
      return super.normalize(modelClass, {
        _id: resourceHash._id,
        profiles: {
          _id: resourceHash.profiles._id,
          firm_name: resourceHash.profiles.firm_name,
          firm_website: resourceHash.profiles.firm_website,
          firm_size: resourceHash.profiles.firm_size
        }
      });
    }

  }

  _exports.default = UserSerializer;
});