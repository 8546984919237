define("itarp-opportunity-designer/routes/details/applied", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "rsvp", "itarp-query-filters/utils/schema/user"], function (_exports, _route, _service, _object, _rsvp, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsAppliedRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)('filters'), _dec3 = (0, _service.inject)('opportunity/authorization'), (_class = class DetailsAppliedRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "filtersService", _descriptor2, this);

      _initializerDefineProperty(this, "workflows", _descriptor3, this);

      _initializerDefineProperty(this, "authorization", _descriptor4, this);

      _initializerDefineProperty(this, "favorites", _descriptor5, this);

      _initializerDefineProperty(this, "markupRate", _descriptor6, this);

      _defineProperty(this, "queryParams", { ..._user.default,
        stageId: {
          refreshModel: true
        },
        place: {
          refreshModel: false
        }
      });

      _defineProperty(this, "workflow", null);
    }

    async model(params) {
      const opportunity = this.modelFor('details').project;
      const id = opportunity.id;
      const filters = this.filtersService.buildQuery(this, params);
      const keywords = this.getKeywords(filters, params);
      const filter = params.stageId ? {
        workflow_stage_id: params.stageId
      } : {};
      const applications = this.store.query('proposal', {
        opportunity: id,
        search: {
          keywords,
          filters,
          page: 1
        },
        filter: filter
      });
      const workflow = await this.workflows.businessWorkflow(opportunity.workflowId);
      const uiWorkflow = await this.workflows.uiWorkflow(opportunity.workflowId);
      return (0, _rsvp.hash)({
        params,
        // for after model hook
        project: opportunity,
        workflow: workflow,
        stagesWithButtons: uiWorkflow === null || uiWorkflow === void 0 ? void 0 : uiWorkflow.stages,
        stageOptions: this.workflows.stageOptionsFor(opportunity.workflowId),
        applications: applications.then(data => {
          if (data.length > 0) {
            this.markupRate.getApplicationRates(data);
          }

          return {
            data,
            meta: data.meta
          };
        }),
        changeStagePermitted: this.authorization.isPermitted(this.authorization.permissions.candidates.edit.stage),
        alreadyFavoriteTalents: this.favorites.findAll('user')
      });
    }

    setupController(controller, model) {
      var _meta$page;

      super.setupController(...arguments);
      const {
        data: applications,
        meta
      } = model.applications;
      controller.setProperties({
        applications,
        project: model.project,
        workflow: model.workflow,
        uiWorkflow: model.uiWorkflow,
        stagesWithButtons: model.stagesWithButtons,
        total: (meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : _meta$page.total) || 0,
        filterItems: { ...this.filterItems
        },
        filterChips: this.filterChips,
        filterSchema: this.queryParams,
        updateQueryParams: this.updateQueryParams,
        stageOptions: model.stageOptions,
        changeStagePermitted: model.changeStagePermitted,
        alreadyFavoriteTalents: model.alreadyFavoriteTalents
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          application: null,
          tab: null,
          keywords: null,
          contractor: null,
          full_time: null,
          part_time: null,
          work_available: null,
          graduation_start: null,
          graduation_end: null,
          rate_start: null,
          rate_end: null,
          job_title: null,
          experience: null,
          part_time_available: null,
          between_time_available: null,
          full_time_available: null,
          remote: null,
          local_commute_only: null,
          willing_to_travel: null,
          willing_to_relocate: null,
          salary_min: null,
          salary_max: null,
          location: null,
          filterItems: null,
          changeStagePermitted: null
        });
        this.filterItems = null;
      }
    }

    beforeModel() {
      if (this.filterItems) return;
      const route = this;
      this.filterItems = this.filtersService.setupFilters(route);
    }

    afterModel(model) {
      const chips = this.filtersService.deserializeFilters(model.params, this.filterItems, this);
      this.filterChips = chips;
    }

    willTransition(transition) {
      transition.data.scroll = false;
      const bubble = true;
      transition.finally(() => {
        delete transition.data.scroll;
      });
      return bubble;
    }

    didTransition() {
      const bubble = false;
      return bubble;
    }

    updateQueryParams() {
      const route = this;
      this.filtersService.serializeFilters(this.filterItems, route);
    }

    getKeywords(filters, {
      keywords
    }) {
      var _filter$skills;

      const filter = filters.find(f => Object.keys(f).includes('skills'));
      let value = keywords || '';

      if (filter && ((_filter$skills = filter.skills) === null || _filter$skills === void 0 ? void 0 : _filter$skills.length) > 0) {
        filters.removeObject(filter);
        const skills = filter.skills.join(',');
        value = value ? value.concat(',', skills) : skills;
      }

      let result = this.filterItems.keywords.serializeSearch(value);
      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authorization", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "favorites", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "markupRate", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "beforeModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "afterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQueryParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getKeywords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getKeywords"), _class.prototype)), _class));
  _exports.default = DetailsAppliedRoute;
});