define("itarp-admin-dashboard/services/account-access", ["exports", "@ember/service", "@ember/object", "@ember/runloop"], function (_exports, _service, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountAccessService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class AccountAccessService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "auth", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _defineProperty(this, "config", {
        switchMap: {
          tod_client: 'expert',
          expert: 'tod_client'
        },
        routes: {
          completion: {
            tod_client: 'itarp-opportunity-designer.browse-public',
            expert: 'itarp-business-application.itarp-opportunity-ui.browse'
          },
          afterSwitch: {
            tod_client: 'account-settings',
            expert: 'itarp-business-application.profile.index'
          },
          signup: {
            expert: 'itarp-business-application.account.get-started',
            tod_client: 'create-client-account'
          }
        },
        messages: {
          completion: {
            tod_client: 'You have switched to the client account, please complete the company information to continue.',
            expert: 'You have switched to the expert account, please complete your profile.'
          },
          switch: {
            tod_client: 'You have successfully switched to the client account.',
            expert: 'You have successfully switched to the expert account.'
          },
          completionRequired: {
            tod_client: 'Please complete the company information to continue.'
          }
        }
      });
    }

    switchAccount(type) {
      this.saveState('performedAccountSwitch', true);
      window.location.href = this.auth.buildSwitchAccountUrl(type);
    }

    authorize(transition) {
      if (!transition.to) return;

      if (this.hasSwitchedAccount() && this.isPostSwitchRoute(transition)) {
        this.postSwitchHandler(transition);
      } else {
        this.handleAuthorization(transition);
      }
    }

    postSwitchHandler(transition) {
      if (this.isAccountComplete()) {
        this.handleRouteAfterSwitch();
        this.showToast(this.config.messages.switch[this.auth.userType]);
      } else {
        this.clearState('intendedUrl');
        this.clearState('performedAccountSwitch');
        this.showToast(this.config.messages.completion[this.auth.userType]);
      }
    }

    handleRouteAfterSwitch() {
      const type = this.auth.userType;
      const intendedUrl = this.getState('intendedUrl');

      if (intendedUrl) {
        this.router.transitionTo(intendedUrl);
        this.clearState('intendedUrl');
      } else {
        this.router.transitionTo(this.config.routes.completion[type]);
      }

      this.clearState('performedAccountSwitch');
    }

    handleAuthorization(transition) {
      const checks = this.getAuthorizationChecks(transition);
      const result = this.performAuthorizationChecks(checks, transition);

      if (!result.success) {
        this.handleAuthorizationFailure(result, transition);
      }
    }

    performAuthorizationChecks(checks, transition) {
      const {
        requiredAccountTypes,
        requiresCompleteAccount
      } = checks;

      if (!this.isAuthorized(requiredAccountTypes)) {
        return {
          success: false,
          reason: 'unauthorized',
          switchType: this.getSwitchType(requiredAccountTypes)
        };
      }

      if (requiresCompleteAccount && !this.isAccountComplete()) {
        return {
          success: false,
          reason: 'incompleteAccount'
        };
      }

      return {
        success: true
      };
    }

    handleAuthorizationFailure(result, transition) {
      transition.abort();

      if (result.reason === 'unauthorized') {
        if (result.switchType) {
          this.saveState('intendedUrl', transition.intent.url);

          if (!this.currentUser.user) {
            this.currentUser.onLoaded(() => {
              this.handleSwitchOrSignup(result);
            });
          } else {
            this.handleSwitchOrSignup(result);
          }
        } else {
          this.router.transitionTo('unauthorized');
        }
      } else if (result.reason === 'incompleteAccount') {
        const route = this.config.routes.afterSwitch[this.auth.userType];
        this.router.transitionTo(route);
        this.showToast(this.config.messages.completionRequired[this.auth.userType]);
      }
    }

    handleSwitchOrSignup(result) {
      if (this.currentUser.user.authorizedAs[result.switchType]) {
        this.switchAccount(result.switchType);
      } else {
        this.router.transitionTo(this.config.routes.signup[result.switchType]);
      }
    }

    getAuthorizationChecks(transition) {
      return {
        requiredAccountTypes: this.getRequiredAccountTypes(transition),
        requiresCompleteAccount: this.getRequiredCompleteAccount(transition)
      };
    }

    getRequiredAccountTypes(transition) {
      let metadata = transition.to.metadata || {};
      let accessControl = metadata.accessControl || {};
      let accountTypes = accessControl.accountTypes;

      if (!accountTypes) {
        accountTypes = this.getParentMetadata(transition.to, ['accessControl', 'accountTypes']);
      }

      return accountTypes;
    }

    getRequiredCompleteAccount(transition) {
      let metadata = transition.to.metadata || {};
      let accessControl = metadata.accessControl || {};
      let requiresCompleteAccount = accessControl.requiresCompleteAccount;

      if (!requiresCompleteAccount) {
        requiresCompleteAccount = this.getParentMetadata(transition.to, ['accessControl', 'requiresCompleteAccount']);
      }

      return requiresCompleteAccount;
    }

    isAuthorized(requiredTypes) {
      return !(requiredTypes !== null && requiredTypes !== void 0 && requiredTypes.length) || requiredTypes.includes(this.auth.userType);
    }

    getSwitchType(requiredTypes) {
      const current = this.auth.userType;
      const switchType = this.config.switchMap[current];
      return switchType && requiredTypes.includes(switchType) ? switchType : null;
    }

    hasSwitchedAccount() {
      return this.getState('performedAccountSwitch');
    }

    isPostSwitchRoute(transition) {
      return transition.to.name === this.config.routes.afterSwitch[this.auth.userType];
    }

    isAccountComplete() {
      const statuses = ['approved', 'pending_approval', 'declined'];
      const types = ['expert', 'tod_client'];
      const accountType = this.auth.userType;
      if (!types.includes(accountType)) return true;
      return accountType === 'expert' && statuses.includes(this.auth.userStatus) || accountType === 'tod_client' && this.auth.companyId !== '1';
    }

    showToast(message) {
      (0, _runloop.schedule)('afterRender', () => this.toast.show(message));
    }

    getParentMetadata(route, keys) {
      let parent = route.parent;

      while (parent) {
        let metadata = parent.metadata || {};
        let value = keys.reduce((acc, key) => {
          const data = acc || {};
          return data[key];
        }, metadata);

        if (value) {
          return value;
        }

        parent = parent.parent;
      }

      return null;
    }

    saveState(key, value) {
      localStorage.setItem(key, value);
    }

    clearState(key) {
      localStorage.removeItem(key);
    }

    getState(key) {
      return localStorage.getItem(key);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "switchAccount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authorize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "authorize"), _class.prototype)), _class));
  _exports.default = AccountAccessService;
});