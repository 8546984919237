define("itarp-opportunity-designer/routes/opportunity/job-details", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "itarp-opportunity-designer/config/environment"], function (_exports, _route, _service, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityJobDetailsRoute = (_dec = (0, _service.inject)('ad-token-service'), _dec2 = (0, _service.inject)('opportunity/wizard'), (_class = class OpportunityJobDetailsRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "collaborators", _descriptor2, this);

      _initializerDefineProperty(this, "auth", _descriptor3, this);

      _initializerDefineProperty(this, "userCompany", _descriptor4, this);

      _initializerDefineProperty(this, "wizard", _descriptor5, this);
    }

    async model(params) {
      let talentcloud;

      if (params.tc_id) {
        talentcloud = this.store.findRecord('talent-cloud', params.tc_id);
      }

      return (0, _rsvp.hash)({
        project: this.modelFor('opportunity'),
        industries: this.store.query('taxonomies-industry', {
          per_page: 'infinity',
          order_by: 'name'
        }),
        talentcloud: talentcloud,
        companyName: this.userCompany.companyName(),
        user: this.getUser()
      });
    }

    beforeModel(transition) {
      const opportunity = this.modelFor('opportunity');

      if (opportunity.isProject === undefined || opportunity.isProject === null) {
        this.transitionTo('opportunity.index');
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      const {
        user
      } = model;
      const parentController = this.controllerFor('opportunity');
      const {
        project,
        industries
      } = model;

      if (!project.hiringManager) {
        project.hiringManager = this.auth.tokenInformation.name;
      }

      if (model.talentcloud) {
        project.practiceAreas.pushObject(model.talentcloud.practiceAreas.toArray().firstObject);
        project.talentClouds.pushObject(model.talentcloud);
      }

      project.companyName = model.companyName;
      project.companyDisplayName = model.companyName;
      const collaboratorsRepository = this.collaborators.initialize(project);
      const hiringManagerAsCollaborator = this.store.createRecord('collaborator', {
        userId: user.id,
        collaboratorUserId: user.id,
        email: user.email,
        user
      });
      collaboratorsRepository.limited.addObject(hiringManagerAsCollaborator);
      controller.setProperties({
        project,
        industries,
        projectInternalId: this.getProjectInternalId(),
        cloudSelectCounter: project.practiceAreas.length + 1,
        collaboratorsRepository
      });
      controller.getTaxonomies();
      parentController.set('afterSave', controller.afterSave);
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          project: null,
          industries: null,
          hasSelectedMoreOptions: false,
          showingAttachment: false,
          showingThumbnail: false,
          cloudSelectCounter: 1,
          collaboratorsRepository: null,
          tc_id: null
        });
      }
    }

    getProjectInternalId() {
      const {
        extension_client_id,
        extension_organization_id
      } = this.auth.tokenInformation;
      const clientId = extension_client_id ? extension_client_id : extension_organization_id;
      return `EXPCL${clientId}`;
    }

    getUser(retry = 0) {
      const userId = this.auth.tokenInformation.sub;

      if (this.store.peekRecord('user', userId)) {
        return Promise.resolve(this.store.peekRecord('user', userId));
      }

      return this.store.findRecord('user', userId).catch(() => {
        if (retry < 3) {
          return this.getUser(retry + 1);
        }
      });
    }

    async activate() {
      super.activate(...arguments);
      const opportunity = this.modelFor('opportunity');
      await this.wizard.setupSteps(opportunity.isProject);
    }

    async deactivate(transition) {
      super.deactivate(...arguments);

      if (transition.to.name === `${_environment.default.engineName}.opportunity.index`) {
        this.wizard.resetSteps();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collaborators", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userCompany", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OpportunityJobDetailsRoute;
});