define("itarp-business-application/controllers/account/get-started", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountGetStartedController = (_class = class AccountGetStartedController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "firmSizes", ['Less than 100', '100 - 1000', 'More than 1000']);

      _initializerDefineProperty(this, "isIndividual", _descriptor, this);

      _initializerDefineProperty(this, "firmNameError", _descriptor2, this);

      _initializerDefineProperty(this, "firmWebsiteError", _descriptor3, this);

      _initializerDefineProperty(this, "firmSizeError", _descriptor4, this);

      _initializerDefineProperty(this, "errorMessages", _descriptor5, this);
    }

    continue() {
      const isValid = this.validateFields();

      if (isValid) {
        if (this.isIndividual) {
          this.transitionToRoute('account.verify-email');
        } else {
          this.model.save({
            adapterOptions: {
              mode: 'firmInfo'
            }
          }).then(() => {
            this.transitionToRoute('account.verify-email');
          });
        }
      }
    }

    validateFields() {
      this.errorMessages = [];
      this.firmNameError = false;
      this.firmWebsiteError = false;
      this.firmSizeError = false;
      if (this.isIndividual) return true;

      if (!this.model.profile.firmName) {
        this.firmNameError = true;
        this.errorMessages.push('Firm name is required');
      }

      if (!this.model.profile.firmWebsite) {
        this.firmWebsiteError = true;
        this.errorMessages.push('Firm website is required');
      }

      if (!this.model.profile.firmSize) {
        this.firmSizeError = true;
        this.errorMessages.push('Firm size is required');
      }

      return !this.firmNameError && !this.firmWebsiteError && !this.firmSizeError;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isIndividual", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firmNameError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "firmWebsiteError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "firmSizeError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "continue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "continue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateFields", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateFields"), _class.prototype)), _class);
  _exports.default = AccountGetStartedController;
});