define("itarp-business-application/controllers/account/verify-email", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/runloop"], function (_exports, _controller, _service, _object, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountGetStartedController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class AccountGetStartedController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "accountAccess", _descriptor2, this);

      _initializerDefineProperty(this, "code", _descriptor3, this);

      _initializerDefineProperty(this, "timeLeft", _descriptor4, this);

      _initializerDefineProperty(this, "errorMsg", _descriptor5, this);

      _initializerDefineProperty(this, "isVerifyInProgress", _descriptor6, this);

      _initializerDefineProperty(this, "isResendDisabled", _descriptor7, this);
    }

    validateVerificationCode() {
      this.errorMsg = '';
      this.isVerifyInProgress = true;
      const email = this.auth.tokenInformation.email;
      const data = JSON.stringify({
        email,
        code: this.code
      });

      try {
        fetch(`/routing/landing-ui-service/verify_email`, {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          return res.json();
        }).then(json => {
          if (json.errors && json.errors.length) {
            this.errorMsg = json.errors[0];
          } else {
            this.accountAccess.switchAccount('expert');
          }
        }).catch(error => {
          this.errorMsg = error.toString().split(':')[1];
          this.isVerifyInProgress = false;
        }).finally(() => {
          this.isVerifyInProgress = false;
        });
      } catch (error) {
        this.errorMsg = error.toString().split(':')[1];
        this.isVerifyInProgress = false;
      }
    }

    sendVerification() {
      this.startTimer();
      const data = JSON.stringify({
        email: this.auth.tokenInformation.email
      });

      try {
        fetch(`/routing/landing-ui-service/send_verification_code`, {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error('Error in email verification');
          }
        }).then(json => {
          this.errorMsg = null;
        }).catch(error => {
          this.errorMsg = error.toString().split(':')[1];
        });
      } catch (error) {
        this.errorMsg = error.toString().split(':')[1];
      }
    }

    initCounter() {
      this.code = '';
      this.errorMsg = '';
      this.startTimer();
    }

    startTimer() {
      (0, _runloop.next)(this, function () {
        this.isResendDisabled = true;
        const timeAfterOneMinute = new Date();
        timeAfterOneMinute.setMinutes(timeAfterOneMinute.getMinutes() + 1);
        this.countDownTime = timeAfterOneMinute.getTime();
        this.runner = this.tick();
      });
    }

    stopTimer() {
      this.isResendDisabled = false;
      (0, _runloop.cancel)(this.runner);
    }

    tick() {
      return (0, _runloop.later)(this, function () {
        const now = new Date().getTime();
        const distance = this.countDownTime - now;

        if (distance < 0) {
          this.stopTimer();
        }

        let minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
        let seconds = Math.floor(distance % (1000 * 60) / 1000);
        minutes = minutes < 0 ? 0 : minutes;
        seconds = seconds < 0 ? 0 : seconds;
        const twoDigitsMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const twoDigitsSeconds = seconds < 10 ? `0${seconds}` : seconds;
        let timeLeft = `${twoDigitsMinutes}:${twoDigitsSeconds}`;
        this.timeLeft = timeLeft;
        this.runner = this.tick();
      }, 1000);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accountAccess", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "code", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "timeLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMsg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isVerifyInProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isResendDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validateVerificationCode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateVerificationCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendVerification", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendVerification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initCounter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initCounter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startTimer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopTimer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "tick"), _class.prototype)), _class));
  _exports.default = AccountGetStartedController;
});